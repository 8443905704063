const floatingIcons = [
    {
        title: "Dribble",
        imageUrl:
            "https://image.flaticon.com/icons/png/512/733/733544.png",
        socialLink: 'https://dribbble.com/amanmool10',
    },
    {
        title: "linkedIn",
        imageUrl:
            "https://image.flaticon.com/icons/png/512/174/174857.png",
        socialLink: 'https://np.linkedin.com/in/aman-mool-53642319b',
    },
    {
        title: "gmail",
        imageUrl:
            "https://image.flaticon.com/icons/png/512/732/732200.png",
        socialLink: 'mailto:amanmool384@gmail.com',
    },
];


const featuredInfo = [
    {
        title: "Foodie",
        info: 'Web Design',
        imageUrl:
            "https://scontent.fktm6-1.fna.fbcdn.net/v/t1.15752-9/201173421_177691607700568_3679517071724823796_n.jpg?_nc_cat=110&ccb=1-3&_nc_sid=ae9488&_nc_ohc=agLrStI-y6UAX9VGRh1&tn=zuopRgvNxRK-mok6&_nc_ht=scontent.fktm6-1.fna&oh=43416d2ec08b73f5d3928a60e3728fdb&oe=60D49407",
    },
    {
        title: "Slotify",
        info: 'Web Design',
        imageUrl:
            "https://scontent.fktm6-1.fna.fbcdn.net/v/t1.6435-9/101538127_2659094311084816_944428746788569088_n.jpg?_nc_cat=100&ccb=1-3&_nc_sid=825194&_nc_ohc=d8lIozRIvmsAX_9BH3M&tn=zuopRgvNxRK-mok6&_nc_ht=scontent.fktm6-1.fna&oh=36f9167f4da3493795b3ac34544102e4&oe=60D4ED5D",
    },
    {
        title: "Aman Logo",
        info: 'Web Design',
        imageUrl:
            "http://me.amanmool.com.np/resources/Frame%203.jpg",
    },
    {
        title: "Qualifurni",
        info: 'Web Design',
        imageUrl:
            "https://scontent.fktm6-1.fna.fbcdn.net/v/t1.15752-9/197712841_513102496775611_6568741503900102808_n.jpg?_nc_cat=108&ccb=1-3&_nc_sid=ae9488&_nc_ohc=ywQffTwTbhYAX818R5v&_nc_ht=scontent.fktm6-1.fna&oh=970040cf54353dda0a37ec3547f01d12&oe=60D62748",
    },

];

const featuredWithSlug = {
    "foodie": {
        title: "Foodie",
        info: 'Web Design',
        imageUrl:
            "http://me.amanmool.com.np/resources/Frame%202.jpg",
    },
    "aman-logo": {
        title: "PortFolio @2020",
        info: 'Website',
        imageUrl:
            "http://me.amanmool.com.np/resources/Frame%203.jpg",
    },
    "slotify": {
        title: "Slotify",
        info: 'Web Design',
        imageUrl:
            "http://me.amanmool.com.np/resources/101538127_2659094311084816_944428746788569088_n.jpg",
    },

    "qualifurni": {
        title: "Qualifurni",
        info: 'Web Design',
        imageUrl:
            "http://me.amanmool.com.np/resources/197712841_513102496775611_6568741503900102808_n.jpg",
    },

};



const GalleryItems = [
    {
        title: "Flappy Bird",
        desc: "Game",
        imageUrl:
            "https://www.thefactsite.com/wp-content/uploads/2014/02/flappy-bird-facts.jpg",
        socialLink: 'https://amanmool03.github.io/flappyBird/',
    },
    {
        title: "Online Piano",
        desc: "Music Product",
        imageUrl:
            "http://me.amanmool.com.np/resources/Capture.png",
        socialLink: 'https://amanmool03.github.io/Piano/',
    },
    {
        title: "Steve Jobs Gallery",
        desc: "Website / web-gallary",
        imageUrl:
            "http://me.amanmool.com.np/resources/steve.png",
        socialLink: 'https://stevejobsgallary.netlify.app/',
    },
];


const featuredSlugInfo = {
    "foodie": {
        title: "Foodie",
        info: 'Web Design and idea',
        desc: "Foodie is a web design for a small team  of serial entrepreneursand digital business experts that are passionate about teaching about cooking in the online platform",
        webUrl: "Under Development",
        objective: "The main objective of this design was to show the recipe items according to the entered food. The users could go on about searching for the required recipes to learn the cooking from",
        myRole: "I worked as an UX/UI designer. My role was to create a simple yet affective looking web design. which the developers would work on",
        workFlow: "For this design. I started with learning for the refrences in google. I got the icon from the fontAwesome which is awesome website for the designers and developers in general.",
        Research: "I started with the researching about the different recipe applications and websites in my home town. one that was very inspiring was the foodmandu and also fooddole. whenever I design, I like to look at many different sources and ideas for inspiration. so for this project in particular i looked for different kinds of apps and I started playing around to get this design ",
        workFlowImg: "https://cdn.sanity.io/images/72m24xcw/production/831c45059a43cec78a369596fb1df8df2e050f68-1300x975.jpg?w=1300&h=975&auto=format",
        projectDate: "Jan 2020",
        imageUrl:
            "http://me.amanmool.com.np/resources/Frame%202.jpg",
    },
    "slotify": {
        title: "Slotify",
        info: 'Website',
        desc: "Slotify is the spotify inspired website which me and my friend plan on working and devloping on our own. It is spotify like site where user can listen to songs of there like.",
        webUrl: "https://amanmool03.github.io/Slotify/",
        objective: "The main objective was to create a clone like spotify where users could listen to the songs of differnet artists for free.",
        workFlow: "I started with getting the inspiration from different sites. spotify being the number one inspiration",
        Research: "I started with the researching about the different music streaming platforms online specially the spotify. I looked up to different designs and ideas on youtube. I found the illustrations used on this website from online.For rest of design its simple",
        workFlowImg: "https://cdn.sanity.io/images/72m24xcw/production/831c45059a43cec78a369596fb1df8df2e050f68-1300x975.jpg?w=1300&h=975&auto=format",
        projectDate: "NOV 2020 (Only Front end done)",
        myRole: "I worked as a freelance UX/UI designer and front-end developer. My role in this project was to do research, branding, lo-fi & hi-fi wireframing, planning for animations and do front-end development using HTML, CSS (SCSS) and JS.",

        imageUrl:
            "http://me.amanmool.com.np/resources/101538127_2659094311084816_944428746788569088_n.jpg",
    },
    "aman-logo": {
        title: "PortFolio ",
        info: 'Website and branding',
        desc: "This was my second portfolio. It was for the year 2020. The design and color scheme was inspired from the portfolio of Brittany change and Awwards site. This was my second portfolio which is entirely made from HTML/CSS",
        webUrl: "http://me.amanmool.com.np/index.html",
        objective: "The main objective was to create a site which could showcase my work as an web designer and developer.",
        workFlow: "It is greeting with awesome logo animation follwed by the introduction of who i am and what i do. there are the list of projects that i have completed till date and way to contact me to work together. ",
        Research: "I started again looking for the much inspiration i could for this portfolio. Then during my research process on what best portfolio site would be i came accross different ideas and awesome website which inspired me to create this portfolio of this design.",
        workFlowImg: "https://cdn.sanity.io/images/72m24xcw/production/831c45059a43cec78a369596fb1df8df2e050f68-1300x975.jpg?w=1300&h=975&auto=format",
        projectDate: "DEC 2020 (PortFolioV3)",
        myRole: "I worked as a freelance UX/UI designer and front-end developer. My role in this project was to do research, branding, lo-fi & hi-fi wireframing, planning for animations and do front-end development using HTML, CSS (SCSS) and JS.",
        imageUrl:
            "http://me.amanmool.com.np/resources/Frame%203.jpg",
    },
    "qualifurni": {
        title: "Qualifurni",
        info: 'Website',
        desc: "Qualifurni is the concept of providing the readymade high quality furnitures in the best of prices with the low and fascinating prices that all can favour",
        webUrl: "https://qualfurni.netlify.app/",
        objective: "The main objective of this website is to provide the user with the platform through which the user can buy the furniture of there demand",
        workFlow: "The main objective was to show the items according to the entered/detected location of the customer. The closest vendors would be selected and the product listed by those vendors would be shown in the app.",
        Research: "I started with researching about the grocery apps like Grofers, Bigbasket, etc who are well known and there is a lot to learn about common patterns from these apps. Whenever I design, I like to look at many different sources of inspiration, not just the competition. So I looked for different kinds of apps in the fields of fashion, restaurant, map, and even Behance app. Then I created a mood board for the necessary features to cover in the app.",
        workFlowImg: "https://cdn.sanity.io/images/72m24xcw/production/831c45059a43cec78a369596fb1df8df2e050f68-1300x975.jpg?w=1300&h=975&auto=format",
        projectDate: "JAN 2021 (Only FrontEnd)",
        myRole: "I worked as a freelance UX/UI designer and front-end developer. My role in this project was to do research, branding, lo-fi & hi-fi wireframing, planning for animations and do front-end development using HTML, CSS (SCSS) and JS.",
        imageUrl:
            "http://me.amanmool.com.np/resources/197712841_513102496775611_6568741503900102808_n.jpg",
    },

};



export { floatingIcons, featuredInfo, GalleryItems, featuredWithSlug, featuredSlugInfo }